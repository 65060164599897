import { useEffect, useState } from "react";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import "./index.css";

export default function InputPhone({ data, setData }) {
  /* Check Phone */
  const [checkPhone, setCheckPhone] = useState("");
  const [validPhone, setValidPhone] = useState("");
  const [focus, setFocus] = useState(false);
  const handleFocus = (e) => {
    setFocus(true);
  };
  const [error, setError] = useState("");

  useEffect(() => {
    setData({
      ...data,
      phoneVeri: formatPhoneNumber(checkPhone)
        .replace(/^0/, "")
        .replace(/ 15-/g, "")
        .replace(/[\s-]+/g, ""),
    });
    if (!isValidPhoneNumber(checkPhone)) {
      if (focus) {
        setValidPhone(false);
        setError("Verifica que tu codigo de area o numero sea correcto");
      }
    } else {
      setValidPhone(true);
      setError("");
    }
    // eslint-disable-next-line
  }, [checkPhone]);

  useEffect(() => {
    setData({ ...data, telefonoValidado: validPhone });
    // eslint-disable-next-line
  }, [validPhone]);

  return (
    <div className="inputDiv">
      <Input
        type="phoneVeri"
        name="telefono"
        placeholder="Ingresá tu Teléfono o Whatsapp"
        required={true}
        country="AR"
        value={checkPhone}
        onChange={setCheckPhone}
        autoComplete="nope"
        onFocus={handleFocus}
        focused={focus.toString()}
      />
      <span className="errorPhone">{error}</span>
    </div>
  );
}
