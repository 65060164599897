import React from "react";
import "./index.css";
import { useCons } from "../context/ConsContext";

const Hero = ({ img, dataButton }) => {
  const { buttonData } = useCons();

  const month = new Date(new Date()).toLocaleString("es", {
    month: "long",
  });

  return (
    <div className="fullH">
      <div className="container-lg">
        <div className="colorBox flex flexRow">
          <div className="textCol">
            <h4 className="relative">Solo por {month}</h4>
            <h1 className="relative">
              Conocé los Planes Adjudicados
              <br />
              <span>listos para retirar.</span>
            </h1>
            <h3 className="relative">
              Tené tu Volkswagen con un plan claro, en pesos sabiendo lo que vas
              a pagar de principio a fin y entregas pactadas por contrato.
            </h3>
            <button
              className="btn whitebtn"
              onClick={() => buttonData(true, dataButton)}
            >
              Consultar ahora
            </button>
          </div>
          <div className="modelo">
            <img src={img} alt="Modelo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
