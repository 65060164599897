import React from "react";
import Hero from "../Hero";
import Modelos from "../Modelos";
import modelo from "../assets/molelos/hero.png";
import Testimonios from "../Testimonios";

const Home = () => {
  return (
    <>
      <Hero img={modelo} />
      <Modelos title="Cotiza tu Próximo Volkswagen" />
      <Testimonios />
    </>
  );
};

export default Home;
